import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate ,  useParams  } from "react-router-dom";
import CookieConsent from './components/Cookie/CookieConsent.jsx';
import Header from "./components/Header";
import Home from "./components/pages/Home";
import Company from "./components/pages/Company";
import CyncTeam from "./components/pages/CyncTeam";
import Footer from "./components/Footer";
import Products from "./components/pages/Products";
import NewsEventsPage from "./components/pages/News-Events";
import Insights from "./components/pages/Insights";
import Error from "./components/Error.jsx";
import ContactForm from "./components/pages/ContactForm.jsx";
import Careers from "./components/pages/Careers.jsx";
import RequestDemo from "./components/pages/RequestDemo.jsx";
import PrivacyPolicy from "./components/pages/PrivacyPolicy.jsx";
import Report from "./components/pages/Report.jsx";
import DisclosurePolicy from "./components/pages/DisclosurePolicy.jsx";
import CyncLOS from "./components/pages/CyncLos.jsx";
import Security from "./components/pages/Security.jsx";
import PostDetail from "./components/insight/PostDetail.jsx";
import CyncABL from "./components/pages/CyncABL.jsx";
import CyncFactoring from "./components/pages/CyncFactoring.jsx";
import CyncSpreading from "./components/pages/CyncSpreading.jsx";
import TagPage from "./components/pages/TagPage.jsx";
import ThankYouPage from "./components/pages/Thankyoupages/Contact/ThankYouPage.jsx";
import ThankYouDemo from "./components/pages/Thankyoupages/Demo/ThankYouDemo.jsx";
import { RoutePaths } from "./PathConfig.js";
import RMA from "./components/Events/RMA.jsx";
import validSlugs from "./components/validSlugs.jsx";
import RmaOff from "./components/Events/RmaOff.jsx";
import Forum from "./components/Events/Forum.jsx";
import Keywords from "./components/pages/keywords.jsx";
import GenaCalender from "./components/pages/Calender/GenaCalender.jsx";
import LydiaCalender from "./components/pages/Calender/LydiaCalender.jsx";
import CyncSyndicated from "./components/pages/CyncSyndicated.jsx"
import FinovateFall from "./components/Events/FinovateFall.jsx";
import Money2020 from "./components/Events/SFnet.jsx";
import SFnet from "./components/Events/SFnet.jsx";


const RedirectWithSlug = () => {
  const { slug } = useParams();
  
  if (validSlugs.includes(slug)) {
    return <Navigate to={`/insights/${slug}`} />;
  }

  return <Navigate to="*" />;
};
function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path={RoutePaths.Home} element={<Home />} />
        <Route path={RoutePaths.Company} element={<Company />} />
        <Route path={RoutePaths.CyncTeam} element={<CyncTeam />} />
        <Route path={RoutePaths.Products} element={<Products />} />
        <Route path={RoutePaths.NewsEvents} element={<NewsEventsPage />} />
        <Route path={RoutePaths.Insights} element={<Insights />} />
        <Route path={RoutePaths.Contact} element={<ContactForm />} />
        <Route path={RoutePaths.Careers} element={<Careers />} />
        <Route path={RoutePaths.RequestDemo} element={<RequestDemo/>} />
        <Route path={RoutePaths.PrivacyPolicy} element={<PrivacyPolicy/>} />
        <Route path={RoutePaths.Report} element={<Report/>} />
        <Route path={RoutePaths.DisclosurePolicy} element={<DisclosurePolicy/>} />
        <Route path={RoutePaths.CyncLos} element={<CyncLOS />} />
        <Route path={RoutePaths.CyncAbl} element={<CyncABL/>} />
        <Route path={RoutePaths.CyncFactoring} element={<CyncFactoring/>} />
        {/* <Route path="/cync-client" element={<CyncClient/>} /> */}
        <Route path={RoutePaths.CyncSpreading} element={<CyncSpreading />} />
        <Route path="/cync-syndicated-lending" element={<CyncSyndicated />} />
        <Route path={RoutePaths.Security} element={<Security />} />
        <Route path="*" element={<Error />} /> 
        <Route path="/insights/:slug"  element={<PostDetail />} />
        <Route path="/tag/:tag" element={<TagPage />} />
        <Route path="/contact/thank-you" element={<ThankYouPage />} /> 
        <Route path="/request-a-demo/thank-you" element={<ThankYouDemo />} /> 
        <Route path="/events/rma-chapter-leadership-conference" element={<RMA/>} /> 
        <Route path="/events/special-rma-pre-show-attendee-offer-from-cync-software/" element={<RmaOff />} /> 
        <Route path="/cync-client" element={<Navigate to="/" />} />
        <Route path="/rma-chapter-leadership-conference" element={<Navigate to="/events/rma-chapter-leadership-conference" />} />
        <Route path="/index.php" element={<Navigate to="/" />} />
        <Route path="/:slug" element={<RedirectWithSlug />} />
        <Route path="/events/4th-annual-distressed-forum-for-bank-special-assets" element={<Forum />} /> 
        <Route path="/keywords/:keyword" element={<Keywords />} />
        <Route path="/los" element={<Navigate to="/cync-loan-origination-system-los" />} />
        <Route path="/abl" element={<Navigate to="/cync-abl" />} />
        <Route path="/factoring" element={<Navigate to="/cync-factoring" />} />
        <Route path="/financial-analyzer" element={<Navigate to="/cync-spreading" />} />
        <Route path="/spreading" element={<Navigate to="/cync-spreading" />} />
        <Route path="/about-us" element={<Navigate to="/company" />} />
        <Route path="/about-cync" element={<Navigate to="/company" />} />
        <Route path="/team-member" element={<Navigate to="/cync-team" />} />
        <Route path="/cync-team.php" element={<Navigate to="/cync-team" />} />
        <Route path="/about-cync.php" element={<Navigate to="/company" />} />
        <Route path="/security.php" element={<Navigate to="/security" />} />
        <Route path="/careers.php" element={<Navigate to="/careers-culture" />} />
        <Route path="/overview.php" element={<Navigate to="/products" />} />
        <Route path="/cync-abl.php" element={<Navigate to="/cync-abl" />} />
        <Route path="/contact-us.php" element={<Navigate to="/contact" />} />
        <Route path="/gena-vanMeter-calendar" element={<GenaCalender />} />  
        <Route path="/lydia-taylor-calendar" element={<LydiaCalender />} /> 
        <Route path="/conference/2024/finovate-fall/" element={<FinovateFall/>} /> 
        {/* <Route path="/conference/2024/sfnet/" element={<SFnet/>} />  */}

      </Routes>
      <Footer />
      <CookieConsent />
    </Router>
  );
}

export default App;
