import React, { useState, useEffect } from "react";
import "../../assets/css/CyncABL.css";
import { Container, Row, Col, Card, Carousel } from "react-bootstrap";
import Button from "../Button";
import HrLines from "../designs/HrLines";
import Typography from "@mui/material/Typography";
import ABLPopup from "../popup/LOS/ABLPopup.jsx";
import VideoModal from "../VideoModal";
import testimonials from "../../Data/testimonialsData.js";
import { ablboxes } from "../../Data/boxesData.js";
import { ablsteps } from "../../Data/stepsData.js";
import reverse from "../../assets/icons/reverse.png";
import { Helmet } from "react-helmet";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AblStepBoxes from "../StepBoxes/AblStepBoxes.jsx";

const flipBox = (id) => {
  const clickedBox = document.getElementById(`box-${id}`);
  const allBoxes = document.querySelectorAll(".box");

  // Remove 'flipped' class from all boxes except the clicked one
  allBoxes.forEach((box) => {
    if (box !== clickedBox) {
      box.classList.remove("flipped");
    }
  });

  // Toggle 'flipped' class for the clicked box
  clickedBox.classList.toggle("flipped");
};

const CyncABL = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [lastActiveStep, setLastActiveStep] = useState(0);

  const handleStepClick = (index) => {
    if (activeStep === index) {
      setActiveStep(null);
    } else {
      setActiveStep(index);
      setLastActiveStep(index);
    }
  };
  const [showPopup, setShowPopup] = useState(false);

  const handleShow = () => setShowPopup(true);
  const handleClose = () => {
    setShowPopup(false);
    setOpen(false);
    setVideoUrl("");
    setCarouselPlaying(true);
  };

  const [open, setOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [carouselPlaying, setCarouselPlaying] = useState(true);

  const handleOpen = (url) => {
    setVideoUrl(url);
    setOpen(true);
    setCarouselPlaying(false);
  };

  return (
    <div>
      <Helmet>
        <title>Asset Based Lending Software - Cync Software®</title>
        <meta
          name="description"
          content="Automate collateral evaluation and borrowing base calculations with our industry-leading asset-based lending platform. Schedule a demo!"
        />
      </Helmet>

      <section className="abltop-sections">
        <Container className="h-100">
          <Row className="h-100 align-items-center">
            <Col lg={9}>
              <div className="content">
                <h4
                  style={{
                    color: "#E93740",
                    fontSize: "50px",
                    fontWeight: "700",
                  }}
                >
                  Cync ABL
                </h4>
                <h1 className="lostopheading">
                  Industry-Leading Asset-Based Lending
                  <br /> Platform To Completely Automate Collateral Evaluation
                  and Borrowing Base Calculations
                </h1>
                <p className="toppara">
                  Evaluate any type of collateral on-demand, including
                  marketable securities. Generate
                  <br /> Borrowing Base Certificates in seconds, with a wide
                  range of calculations and the
                  <br /> flexibility to mirror your loan agreements. Improve
                  risk management with dynamic
                  <br /> reports and detailed trend cards, all while enhancing
                  the borrower experience.
                </p>
                <Button size="small" color="primary" onClick={handleShow}>
                  Product Demo
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="space"></div>

      <section>
        <Container className="h-100">
          <h1 className="privacyheading">
            Supercharge Your Lending With Cync ABL
          </h1>
          <HrLines />
          <br />
          <p className="contentpara">
            Credit analysts often spend hours, if not days, populating
            spreadsheets to evaluate collateral. Not to mention the struggles of
            dealing with aging reports that run into hundreds of pages. For
            lenders, calculating ineligibles and performing trend analysis can
            take significant time to complete and review. The whole process is
            painstakingly slow and cumbersome.
          </p>
          <p className="contentpara">
            Stop wasting your smarts on manual tasks and say hello to Cync ABL.
            Do everything you do today, but faster and more accurately.  Handle
            any type of borrower collateral and gain the flexibility to
            accommodate any deal structure. Churn out error-free Borrow Base
            Certificates in minutes and allow your credit analysts to manage
            more portfolios or focus on higher-value tasks that require their
            expertise.
          </p>
        </Container>
      </section>
      <div className="space"></div>

      <section className="benefits-with-grids">
        <div className="benefitsgrid" style={{ width: "375px" }}>
          <div className="icon">
            <img
              src={require("../../assets/icons/timesaving.png")}
              alt="Icon 1"
            />
          </div>
          <div className="text">
            <h6>96%</h6>
            <h5>Reduction in Time to Generate Borrowing Base Certificate</h5>
          </div>
        </div>
        <div className="benefitsgrid" style={{ width: "375px" }}>
          <div className="icon">
            <img
              src={require("../../assets/icons/portfoliowhite.png")}
              alt="Icon 2"
            />
          </div>
          <div className="text">
            <h6>2X</h6>
            <h5>Portfolios Handled per Credit Analyst</h5>
          </div>
        </div>
        <div className="benefitsgrid" style={{ width: "375px" }}>
          <div className="icon">
            <img src={require("../../assets/icons/redu.png")} alt="Icon 3" />
          </div>
          <div className="text">
            <h6>25%</h6>
            <h5>Reduction in Costs</h5>
          </div>
        </div>
      </section>
      <div className="space"></div>

      <section>
      <Container>
        <h1 className="privacyheading">Why Cync ABL?</h1>
        <HrLines />
        <div className="space"></div>
        <AblStepBoxes />
      </Container>
    </section>

      <div className="space"></div>

      <section>
        <Container>
          <h1 className="privacyheading">Expansive Feature Set</h1>
          <HrLines />
          <div className="space"></div>
          <Row className="box-row">
            {ablboxes.map((box) => (
              <Col key={box.id} md={3} sm={6} xs={12} className="box-col">
                <Card
                  className="box"
                  id={`box-${box.id}`}
                  onClick={() => flipBox(box.id)}
                >
                  <div className="front">
                    <div className="icon-top-right">
                      <img src={reverse} alt="Extra Icon" />
                    </div>
                    <img src={box.icon} alt={box.title} />
                    <br />
                    <h3>{box.title}</h3>
                  </div>
                  <div className="back">
                    <p>{box.description}</p>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <div className="space"></div>

      <section className="testimoni">
        <Carousel
          indicators={true}
          controls={false}
          interval={carouselPlaying ? 15000 : null}
          fade={true} // Add fade effect for smooth transition
          className="carousel-container"
        >
          {testimonials.map((testimonial, index) => (
            <Carousel.Item key={index} className="carousel-item">
              <img
                className="d-block w-100 carousel-image"
                src={testimonial.image}
                alt={`Slide ${index + 1}`}
              />
              <Carousel.Caption className="carousel-caption-custom">
                <h1 className="headingwhite">{testimonial.heading}</h1>
                <p className="testimobpara">{testimonial.text}</p>
                <p className="author-text">{testimonial.author}</p>
                <Button
                  size="sm"
                  variant="light"
                  onClick={() => handleOpen(testimonial.videoUrl)}
                >
                  Watch Video
                </Button>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
        <VideoModal open={open} handleClose={handleClose} videoUrl={videoUrl} />
      </section>

      <div className="space"></div>

      <section className="demo-section">
        <div className="demo-content">
          <h1>Supercharge Your Lending Now!</h1>
          <HrLines />
          <div className="space"></div>
          <Button size="small" color="primary" onClick={handleShow}>
            Product Demo
          </Button>
          <ABLPopup show={showPopup} handleClose={handleClose} />
        </div>
      </section>
    </div>
  );
};

export default CyncABL;
