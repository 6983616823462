import React from "react";
import "../../assets/css/AblStepBoxes.css";

const AblStepBoxes = () => {
  return (
    <div className="roadmap-container">
      <div className="roadmap-box left-box box1 ablbox1">
        <h2 style={{color:'#2971EB'}}>Automate Borrowing Base Certificate Generation</h2>
        <p>
          Stop spending hours manually sifting through hundreds of pages of
          documentation. Instead, just upload or import data via APIs and let
          Cync ABL evaluate the collateral and compute the borrowing base
          automatically. Cync ABL’s flexibility enables either the lender or the
          borrowers to generate the Borrowing Base Certificate according to the
          loan agreement.
        </p>
        <div className="arrow left-arrow"></div>
      </div>
      <div className="roadmap-box right-box box2 ablbox2">
        <h2 style={{color:'#2971EB'}}>Define Ineligibles to Process Collateral Files</h2>
        <p>
          Define sub-limits at borrower-level, by collateral type, or configure
          them based on the loan agreement. Offer different interest rates
          according to collateral type while consolidating under a single
          accounts receivable line. Establish Seasonal Advance Rates for
          borrowers with a peak-season. Take Aging, Contra, Global Assignments
          into consideration in your ineligibles calculations. In short, do
          everything that you do manually today quickly and accurately via Cync
          ABL.
        </p>
        <div className="arrow right-arrow"></div>
      </div>
      <div className="roadmap-box left-box box3 ablbox3">
        <h2 style={{color:'#2971EB'}}>
          Monitor Every Kind of Collateral, Including Marketable Securities
        </h2>
        <p>
          Lend with confidence to borrowers with diverse set of collateral
          including account receivables, inventory, real estate, equipment,
          government contracts (GovCon) or agricultural assets. Additionally,
          with Cync ABL, you can monitor and evaluate marketable securities,
          pulling in the value in real-time to mitigate risk.
        </p>
        <div className="arrow left-arrow"></div>
      </div>
      <div className="roadmap-box right-box box4 ablbox4">
        <h2 style={{color:'#2971EB'}}>Instantly Identify Affiliated Entities</h2>
        <p>
          Identify parent companies, subsidiaries, and related parties upon file
          upload. Gain insights into customer concentration to comprehensively
          assess potential areas of risk for both borrowers and the lender.
          Accurately evaluate collateral by identifying contras within your
          accounts receivable and accounts payable.
        </p>
        <div className="arrow right-arrow"></div>
      </div>

      {/* Vertical connecting line */}
      <div className="vertical-line"></div>
    </div>
  );
};

export default AblStepBoxes;
