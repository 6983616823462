import React from "react";
import "../../assets/css/SpreadingStepBoxes.css";

const SpreadingStepBoxes = () => {
  return (
    <div className="roadmap-container">
      <div className="roadmap-box left-box box1">
        <h2 style={{color:'#2971EB'}}>Improve Staff Productivity with Automation</h2>
        <p>
          Take the focus off mundane, repetitive tasks by allowing Cync
          Spreading’s automation to take over. Stop spending hours and instead
          have top-notch artificial intelligence rapidly churn out
          GAAP-compliant spreads for analysis. Or automatically track
          relationship-specific covenants and ticklers, allowing loan officers
          and analysts to focus solely on verifying covenant evaluations.
        </p>
        <div className="arrow left-arrow"></div>
      </div>
      <div className="roadmap-box right-box box2">
        <h2 style={{color:'#2971EB'}}>Speedy Implementation with Customizable Spreading Templates</h2>
        <p>
          Quickly establish the foundation for importing statements from
          commercial, real estate, or individual clients by utilizing the
          built-in master spreading templates. Alternatively, modify the master
          templates by linking various financial statement types to design
          customized templates based on the category of spread, client,
          industry, or other criteria.
        </p>
        <div className="arrow right-arrow"></div>
      </div>

      {/* Vertical connecting line */}
      <div className="vertical-line"></div>
    </div>
  );
};

export default SpreadingStepBoxes;
